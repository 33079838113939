import { WRPost } from '@utils/api/server';
import { WP_INTERVIEWS_ID, WP_PODCASTS_ID } from 'enums/wordpress';
import { ArticleI, ArticleType } from 'types/article';

export const getArticleType = (article: ArticleI | WRPost): ArticleType => {
  const categoryIds = article.categories.map((category) =>
    typeof category === 'number' ? category : category.id,
  );

  if (categoryIds.includes(WP_INTERVIEWS_ID[process.env.NODE_ENV]['pl'])) {
    return 'interview';
  }

  if (categoryIds.includes(WP_PODCASTS_ID[process.env.NODE_ENV]['pl'])) {
    return 'podcast';
  }

  if (article.type === 'videos') {
    return 'video';
  }

  return 'article';
};