import cn from 'classnames';

import ArticleBadge from './ArticleBadge';

import { Breakpoint } from '@hooks/useBreakpoints';
interface LiveBadgeProps {
  className?: string;
  full?: Breakpoint | 'always';
}

export default function LiveBadge({ className, full }: LiveBadgeProps) {
  return (
    <ArticleBadge
      full={full}
      className={cn('bg-red-500 text-white', className)}
      renderIcon={(iconClassName) => (
        <div className={cn('relative rounded-full bg-white', iconClassName)}>
          <div
            className={cn(
              'absolute left-0 top-0 animate-ping rounded-full bg-white',
              iconClassName,
            )}
          />
        </div>
      )}
    >
      live
    </ArticleBadge>
  );
}
