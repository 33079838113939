import PodcastOverlay from 'public/static/post/podcast.svg';
import VideoOverlay from 'public/static/post/video.svg';
import { ArticleType } from 'types/article';

interface ArticleImageOverlayProps {
  type: ArticleType;
}

export default function ArticleImageOverlay({
  type,
}: ArticleImageOverlayProps) {

  if ((type === 'article') || (type === 'interview')) {
    return (<></>);
  } else if (type === 'video') {
    return (
      <div className='absolute inset-0 flex items-center justify-center'>
        <VideoOverlay />
      </div>
    );
  } else if (type === 'podcast') {
    return (
      <div className='absolute inset-0 flex items-center justify-center'>
        <PodcastOverlay />
      </div>
    );
  }
}
