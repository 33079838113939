import { logger } from '@logger';
import { fetchApi } from '@utils/api/client';
import { ArticleI } from 'types/article';

export type FetchParams = {
  limit?: number;
  lastDate?: any,
  langName: string,
  authorSlug?: string,
  relatedPostsLimit?: number,
  category?: string,
}

export const fetchArticlesList = async (props: FetchParams) => {

  const params = {
    lastDate: props.lastDate,
    limit: props.limit ?? 10,
    authorSlug: props.authorSlug,
    langName: props.langName,
    relatedPostsLimit: props.relatedPostsLimit,
    category: props.category,
  };

  Object.keys(params).forEach(key => !params[key] ? delete params[key] : {});
  try {
    return (await fetchApi<ArticleI[]>(
      `${process.env.NEXT_PUBLIC_API}/posts`,
      {
        params
      }
    )).data;
  } catch (e) {
    logger.error(e);
    return [];
  }
}